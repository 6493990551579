import BrowserDetection from '../base/BrowserDetection';

/**
 * DomAccess Class
 */

export default class DomAccess {

    /**
     * Wraps the baseElement.querySelector() method to throw
     * an Error if selector won't being found to stop JS execution
     * if strict is set true
     * @param baseElement
     * @param selector
     * @param strict
     * @returns {Element | any | boolean}
     */
    static querySelector(baseElement, selector, strict = true) {
        let item = baseElement.querySelector(selector) || false;

        if (!item && strict) {
            throw new Error(`Element "${selector}" not found`);
        }

        return item;
    }

    /**
     * Just wraps the baseElement.querySelector() method for now
     * @param baseElement
     * @param selector
     * @param strict
     * @returns {NodeListOf<SVGElementTagNameMap[[*]]> | NodeListOf<HTMLElementTagNameMap[[*]]> | NodeListOf<Element>}
     */
    static querySelectorAll(baseElement, selector, strict = true) {
        return baseElement.querySelectorAll(selector);
    }

    /**
     * Returns the requested attribute from the injected element
     * and will throw an Error in case the attribute won't exist if strict is set true
     * @param element
     * @param attribute
     * @param strict
     * @returns {string | * | boolean}
     */
    static getAttribute(element, attribute, strict = true) {
        let value = element.getAttribute(attribute) || false;

        if (!value && strict) {
            throw new Error(`Attribute "${attribute}" not found`);
        }

        return value;
    }

    /**
     * ClosestElement Plugin
     * Wrapper for the es6 closest method with a polyfill to provide the function for IE11.
     *
     * @param selector
     * @param query
     * @param strict
     * @returns {Element | any | boolean}
     *
     */
    static closest(selector, query, strict = true) {

        if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;
        }

        if (!Element.prototype.closest) {
            Element.prototype.closest = function (s) {
                var el = this;

                do {
                    if (el.matches(s)) return el;
                    el = el.parentElement || el.parentNode;
                } while (el !== null && el.nodeType === 1);
                return null;
            };
        }

        let item = selector.closest(query) || false;

        if (!item && strict) {
            throw new Error(`Closest element "${selector}" not found`);
        }

        return item;
    }

    /**
     * remove Element
     * Wrapper for the es6 remove method and poly fill.
     *
     * @param element
     * @param strict
     *
     */
    static remove(element, strict = true) {
        if (element === null && strict === false) {
            return;
        }

        if (BrowserDetection.isIE()) {
            element.parentNode.removeChild(element);
        } else {
            element.remove();
        }
    }
}