/**
 * BrowserDetection Plugin
 *
 * @description: Plugin to detect the current browser
 */

const USER_AGENT = window.navigator.userAgent;

export default class BrowserDetection {

    /**
     * Checks for IE11
     * @returns {boolean}
     */
    static isIE() {
        return USER_AGENT.indexOf('Trident') > 0;
    }

    /**
     * Checks for Edge
     * @returns {boolean}
     */
    static isEdge() {
        return USER_AGENT.indexOf('Edge') > 0;
    }

    /**
     * Checks for Chrome
     * @returns {boolean}
     */
    static isChrome() {
        return USER_AGENT.indexOf('Chrome') > 0 && !BrowserDetection.isEdge();
    }

    /**
     * Checks for Firefox
     * @returns {boolean}
     */
    static isFirefox() {
        return USER_AGENT.indexOf('Firefox') > 0
    }

    /**
     * Checks for Safari
     * @returns {boolean}
     */
    static isSafari() {
        return USER_AGENT.indexOf('Safari') > 0 && !BrowserDetection.isChrome();
    }

}