/**
 * Minimal Header Plugin
 *
 * @description: The Minimal Header plain JS.
 */
import DomAccess from "../../base/DomAccess";
import EventDispatcher from "../../base/EventDispatcher";

const IS_MINIMAL_CLS = "is-sticky";

const EVENT_HEADER_MINIMAL = 'MinimalHeader/setMinimal';
const EVENT_UNSET_HEADER_MINIMAL = 'MinimalHeader/unsetMinimal';

const scrollUp = "js-scroll-up";
const scrollDown = "js-scroll-down";
let lastScroll = 0;
let body = document.body;

export default class MinimalHeader {

    /**
     * Constructor.
     * @param selector
     */
    constructor(selector = ".js-sticky-header") {
        this.selector = selector;
        this.item = DomAccess.querySelector(document, selector);
        this.isMinimal = false;

        this._registerEvents();
    }

    /**
     * Register events
     * @private
     */
    _registerEvents() {
        window.addEventListener('scroll', this._onScroll.bind(this), {passive: true});
    }


    /**
     * Handle scroll event to set the header minimal/full
     * @private
     */
    _onScroll() {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            body.classList.remove(scrollUp);
            return;
        }

        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
            // down
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
            // up
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;

        /*
        if (this._isScrolledBelowOffset() && this.isMinimal === false) {
            this._setMinimal();
        }
        if (this._isScrolledBelowOffset() === false && this.isMinimal === true) {
            this._unsetMinimal();
        } */
    }

    /**
     * Handles to set the header minimal
     * @private
     */
    _setMinimal() {
        // stop if already minimal
        if (this.isMinimal) return;

        // set minimal state true
        this.isMinimal = true;

        // set minimal class
        this.item.classList.add(IS_MINIMAL_CLS);

        // dispatch event other JS codes will listen to if the header will go minimal
        EventDispatcher.dispatch(document, EVENT_HEADER_MINIMAL);
    }

    /**
     * Handles to set the header in it's full appearance
     * @private
     */
    _unsetMinimal() {
        // stop if already full
        if (this.isMinimal === false) return;

        // set minimal state false
        this.isMinimal = false;

        // remove minimal class
        this.item.classList.remove(IS_MINIMAL_CLS);

        EventDispatcher.dispatch(document, EVENT_UNSET_HEADER_MINIMAL);
    }

    /**
     * Verify if user scrolled below the sticky offset threshold
     * @returns {boolean}
     * @private
     */
    _isScrolledBelowOffset() {
        let stickyOffset = DomAccess.querySelector(document, '.js-sticky-header').offsetHeight;

        return (window.pageYOffset > 0);
    }

    /**
     * @returns {string}
     * @constructor
     */
    static EVENT_HEADER_MINIMAL() {
        return EVENT_HEADER_MINIMAL;
    }

    /**
     * @returns {string}
     * @constructor
     */
    static EVENT_UNSET_HEADER_MINIMAL() {
        return EVENT_UNSET_HEADER_MINIMAL;
    }

}