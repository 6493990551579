import { tns } from "./../../../node_modules/tiny-slider/src/tiny-slider";
require('./../../../node_modules/tiny-slider/dist/tiny-slider.css');

class Slider {

    static stageSlider() {
        let sliderCls = '.ce_stage-slider';

        console.log(document.querySelector(sliderCls));

        if (document.querySelector(sliderCls) === null) return;

        let tinyslider = tns({
            container: sliderCls,
            items: 1,
            mode: 'gallery',
            slideBy: 'page',
            autoplay: true,
            autoplayTimeout: 10000,
            autoplayHoverPause: true,
            autoplayButtonOutput: false,
            speed: 1500,
            controls: false,
            controlsPosition: 'bottom',
            controlsText: ['<i class="zc-arrow-left"></i>', '<i class="zc-arrow-right"></i>'],
            navPosition: 'bottom',
            responsive: {
                768: {
                    controls: true,
                }
            }
        });
    }

    static newslist() {
        let sliderCls = '.mod_newslist--inner';

        if (document.querySelector(sliderCls) === null) return;

        let tinyslider = tns({
            container: sliderCls,
            items: 1,
            fixedWidth: 245,
            gutter: 32,
            loop: false,
            speed: 1500,
            controls: false,
            controlsPosition: 'bottom',
            controlsText: ['<i class="zc-arrow-left"></i>', '<i class="zc-arrow-right"></i>'],
            navPosition: 'bottom',
            responsive: {
                768: {
                    fixedWidth: false,
                    items: 2,
                    controls: true,
                },
                992: {
                    items: 3,
                }
            }
        });
    }

    static servicelist() {
        let sliderCls = '.servicelist .mod_newslist--inner';

        if (document.querySelector(sliderCls) === null) return;

        let tinyslider = tns({
            container: sliderCls,
            items: 1,
            fixedWidth: 245,
            gutter: 32,
            loop: false,
            speed: 1500,
            controls: false,
            controlsPosition: 'bottom',
            controlsText: ['<i class="zc-arrow-left"></i>', '<i class="zc-arrow-right"></i>'],
            navPosition: 'bottom',
            responsive: {
                768: {
                    fixedWidth: false,
                    items: 2,
                    controls: true,
                },
                992: {
                    items: 3,
                }
            }
        });
    }
}

export default Slider;