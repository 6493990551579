/**
 * EventDispatcher
 *
 * @description: The EventDispatcher plain JS.
 */
import 'custom-event-polyfill';
import BrowserDetection from "./BrowserDetection";

export default class EventDispatcher {

    /**
     * Dispatch an event
     * @param element
     * @param event
     */
    static dispatch(element, event) {
        if (BrowserDetection.isIE()) {
            let ieEvent = document.createEvent('Event');
            ieEvent.initEvent(event, true, true);

            element.dispatchEvent(ieEvent);
        } else {
            element.dispatchEvent(new Event(event));
        }
    };

    /**
     * Dispatch a custom event with additional data
     * @param element
     * @param customEvent
     * @param data
     */
    static dispatchCustom(element, customEvent, data = {}) {
        element.dispatchEvent(new CustomEvent(customEvent, data));
    }
}